import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { page_access } from '../services/Api';
import Sidebar from '../Layout/Sidebar';

// Cache to store access data for each pageid
const accessCache = new Map();

const AccessControlRoute = ({ element, permission, pageid }) => {
  const location = useLocation();
  const [accessData, setAccessData] = useState({});
  const [loading, setLoading] = useState(true);

  // Fetch access data from API or cache
  const fetchAccessData = async () => {
    try {
      // Check if the access data is already in the cache
      if (accessCache.has(pageid)) {
        setAccessData(accessCache.get(pageid));
      } else {
        // Fetch the access data from the API
        const response = await page_access(pageid);
        const accessObject = Array.isArray(response.data.Data) && response.data.Data.length > 0
          ? response.data.Data[0]
          : {};
        setAccessData(accessObject);
        // Store the fetched data in the cache
        accessCache.set(pageid, accessObject);
      }
    } catch (error) {
      console.error('Error fetching access data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Store the current path in cookies, except for '/', '/logout', and '/forgotpass'
    if (location.pathname !== '/' && location.pathname !== '/logout' && location.pathname !== '/forgotpass') {
      Cookies.set('previousRoute', location.pathname, { expires: 7 });
    }

    fetchAccessData(); // Fetch access data whenever the route changes
  }, [location.pathname, pageid]);

  // Check if the user has permission
  const hasPermission = accessData && accessData[permission] === true;

  if (loading) {
    return <div>Loading...</div>; // Show loading while fetching access data
  }

  // If permission is not available, show a "No Access" message
  if (!hasPermission) {
    return (
      <div className='d-flex justify-content-between'>
        <div>
          <Sidebar />
        </div>
        <div className='col-10 dash-pad login-container' style={{ backgroundColor: 'rgba(247, 244, 255, 1)', minHeight: '89vh' }}>
          <div className="banner col-12 align-items-center" style={{ backgroundColor: 'white', minHeight: "98%" }}>
            <i class="fa-sharp-duotone fa-solid fa-hand" style={{ fontSize: "4vw", color: 'rgb(47, 111, 146)', marginBottom: "20px" }}></i>
            <p className="side-title" style={{ color: "rgb(47, 111, 146)", fontWeight: "500", fontSize: "20px" }}>You do not have access to this route.</p>
          </div>
        </div>
      </div>
    );
  }

  return element;
};

export default AccessControlRoute;