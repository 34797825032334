import React from "react";
import CryptoJS from "crypto-js";
import { getApiSecretKey } from "../../env";

const secretKey = getApiSecretKey();

export const encryption = (str) => {
  return CryptoJS.AES.encrypt(str, secretKey).toString();
};

// export const decryption = (str) => {
//   return CryptoJS.AES.decrypt(str, secretKey).toString();
// }

export const decryption = (str) => {
  try {
    if (!str) {
      return;
    }

    const bytes = CryptoJS.AES.decrypt(str, secretKey);
    if (!bytes || bytes.sigBytes <= 0) {
      throw new Error("Invalid encrypted data");
    }

    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    if (!decryptedData) {
      throw new Error("Decryption failed, output is empty");
    }

    return decryptedData;
  } catch (error) {
    console.error("Error during decryption:", error.message);
    return ""; // Return an empty string instead of crashing
  }
};
