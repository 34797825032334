import React from "react";

const PrivacyPolicy = () => {
  const Header = () => (
    <nav className="navbar fixed-top" style={{ backgroundColor: "white" }}>
      <div className="container d-flex align-items-center justify-content-between">
        <img
          src="/assets/images/1920x160.png"
          width="15%"
          alt="ceyone logo"
          className="me-2"
        />
      </div>
    </nav>
  );

  const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
      <footer
        className="text-light text-center py-3 mt-5"
        style={{ backgroundColor: "#2f6f92" }}
      >
        <div className="container">
          &copy; {currentYear} CEYONE.ev All Rights Reserved.
        </div>
      </footer>
    );
  };

  return (
    <>
      <Header />
      <div className="container-fluid px-5 mb-5" style={{ marginTop: "90px" }}>
        <h3 className="text-start mb-3">Terms & Conditions</h3>
        <ol type="A" className="text-bold">
          <li className="mb-2 text-start">
            This document is an electronic record in terms of Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000. This
            electronic record is generated by a computer system and does not
            require any physical or digital signatures.
          </li>

          <li className="mb-2 text-start">
            This document is published in accordance with the provisions of Rule
            3 (1) of the Information Technology (Intermediaries guidelines)
            Rules, 2011 that require publishing the rules and regulations,
            privacy policy, and Terms of Use for access or usage of domain name
            [Enter domain name] (“Website”), including the related mobile site
            and mobile application (hereinafter referred to as “Platform”).
          </li>
          <li className="mb-2 text-start">
            The Platform is owned by (Ceyone Future Tech Private Limited), a
            company incorporated under the Companies Act, 1956 with its
            registered office at (294/1 Shyam Nagar, Chincholiraowadi Road,
            Harangul (B.K), Latur 413531, Maharashtra India.) (hereinafter
            referred to as "Platform Owner", “we”, “us”, “our”).
          </li>
          <li className="mb-2 text-start">
            Your use of the Platform and services and tools are governed by the
            following terms and conditions ("Terms of Use") as applicable to the
            Platform including the applicable policies which are incorporated
            herein by way of reference. If You transact on the Platform, You
            shall be subject to the policies that are applicable to the Platform
            for such transaction. By mere use of the Platform, You shall be
            contracting with the Platform Owner and these terms and conditions
            including the policies constitute Your binding obligations, with
            Platform Owner. These Terms of Use relate to your use of our
            website, goods (as applicable) or services (as
            applicable)(collectively, “Services”). Any terms and conditions
            proposed by You which are in addition to or which conflict with
            these Terms of Use are expressly rejected by the Platform Owner and
            shall be of no force or effect. These Terms of Use can be modified
            at any time without assigning any reason. It is your responsibility
            to periodically review these Terms of Use to stay informed of
            updates.
          </li>
          <li className="mb-2 text-start">
            For the purpose of these Terms of Use, wherever the context so
            requires "you", “your” or "user" shall mean any natural or legal
            person who has agreed to become a user/buyer on the Platform.
          </li>
          <li className="mb-2 text-start">
            ACCESSING, BROWSING OR OTHERWISE USING THE PLATFORM INDICATES YOUR
            AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE,
            SO PLEASE READTHETERMSOF USE CAREFULLY BEFORE PROCEEDING.
          </li>
        </ol>

        <h5 className="text-start mb-3">
          The use of Platform and/or availing of our Services is subject to the
          following Terms of Use :
        </h5>
        <ol className="" type="I">
          <li className="text-start mb-2">
            To access and use the Services, you agree to provide true, accurate
            and complete information to us during and after registration, and
            you shall be responsible for all acts done through the use of your
            registered account on the Platform.
          </li>
          <li className="text-start mb-2">
            Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness or
            suitability of the information and materials offered on this website
            or through the Services, for any specific purpose. You acknowledge
            that such information and materials may contain inaccuracies or
            errors and we expressly exclude liability for any such inaccuracies
            or errors to the fullest extent permitted by law.
          </li>
          <li className="text-start mb-2">
            Your use of our Services and the Platform is solely and entirely at
            your own risk and discretion for which we shall not be liable to you
            in any manner. You are required to independently assess and ensure
            that the Services meet your requirements.
          </li>
          <li className="text-start mb-2">
            The contents of the Platform and the Services are proprietary to us
            and are licensed to us. You will not have any authority to claim any
            intellectual property rights, title, or interest in its contents.
            The contents includes and is not limited to the design, layout, look
            and graphics.
          </li>
          <li className="text-start mb-2">
            You acknowledge that unauthorized use of the Platform and/or the
            Services may lead to action against you as per these Terms of Use
            and/or applicable laws.
          </li>
          <li className="text-start mb-2">
            You agree to pay us the charges associated with availing the
            Services.
          </li>
          <li className="text-start mb-2">
            You agree not to use the Platform and/ or Services for any purpose
            that is unlawful, illegal or forbidden by these Terms, or Indian or
            local laws that might apply to you.
          </li>
          <li className="text-start mb-2">
            You agree and acknowledge that website and the Services may contain
            links to other third party websites. On accessing these links, you
            will be governed by the terms of use, privacy policy and such other
            policies of such third party websites. These links are provided for
            your convenience for provide further information.
          </li>
          <li className="text-start mb-2">
            You understand that upon initiating a transaction for availing the
            Services you are entering into a legally binding and enforceable
            contract with the Platform Owner for the Services.
          </li>
          <li className="text-start mb-2">
            You shall indemnify and hold harmless Platform Owner, its
            affiliates, group companies(as applicable) and their respective
            officers, directors, agents, and employees, from any claim or
            demand, or actions including reasonable attorneys’ fees, made by any
            third party or penalty imposed due to or arising out of Your breach
            of this Terms of Use, privacy Policy and other Policies, or Your
            violation of any law, rules or regulations or the rights (including
            infringement of intellectual property rights) of a third party.
          </li>
          <li className="text-start mb-2">
            In no event will the Platform Owner be liable for any indirect,
            consequential, incidental, special or punitive damages, including
            without limitation damages for loss of profits or revenues, business
            interruption, loss of business opportunities, loss of data or loss
            of other economic interests, whether in contract, negligence, tort
            or otherwise, arising from the use of or inability to use the
            Services, however caused and whether arising in contract, tort,
            negligence, warranty or otherwise, exceed the amount paid by You for
            using the Services giving rise to the cause of action or Rupees One
            Hundred (Rs. 100) whichever is less
          </li>
          <li className="text-start mb-2">
            Notwithstanding anything contained in these Terms of Use, the
            parties shall not be liable for any failure to perform an obligation
            under these Terms if performance is prevented or delayed by a force
            majeure event.
          </li>
          <li className="text-start mb-2">
            These Terms and any dispute or claim relating to it, or its
            enforceability, shall be governed by and construed in accordance
            with the laws of India.
          </li>
          <li className="text-start mb-2">
            All disputes arising out of or in connection with these Terms shall
            be subject to the exclusive jurisdiction of the courts in (Latur,
            Maharashtra)
          </li>
          <li className="text-start mb-2">
            All concerns or communications relating to these Terms must be
            communicated to us using the contact information provided on this
            website or App.
          </li>
        </ol>

        <h4 className="text-2xl font-semibold mt-6 mb-3 text-start">
          Cancellation and Refund Policy
        </h4>
        <p className="mb-3 text-start font-semibold">
          This cancellation policy outlines about how you can cancel or seek a
          refund for a product / service that you have purchased through the
          Platform. Under this policy :
        </p>
        <ol className="" type="I">
          <li className="text-start mb-2">
            Cancellations will only be considered if the request is made [1] day
            of placing the order. However, cancellation request may not be
            entertained if the orders have been communicated to such
            sellers/merchant(s) listed on the Platform and they have initiated
            the process of shipping them, or the product is out for delivery. In
            such an event, you may choose to reject the product at the doorstep
          </li>
          <li className="text-start mb-2">
            [Ceyone Future Tech Private Limited] does not accept cancellation
            requests for perishable items like flowers, eatables, Services,
            subscription plans etc. However, the refund / replacement can be
            made if the user establishes that quality of the product delivered
            is not good.
          </li>
          <li className="text-start mb-2">
            In case of receipt of damaged or defective items, please report to
            our customer service team. The request would be entertained once the
            seller/ merchant listed on the Platform, has checked and determined
            the same at its own end. This should be reported within [1] days of
            receipt of products. In case you feel that the product received is
            not as shown on the site or as per your expectations, you must bring
            it to the notice of our customer service within [1] of receiving the
            product. The customer service team after looking into your complaint
            will take an appropriate decision.
          </li>
          <li className="text-start mb-2">
            In case of complaints regarding the products that come with a
            warranty from the manufacturers, please refer the issue to them
          </li>
          <li className="text-start mb-2">
            In case of any refunds approved by [Ceyone Future Tech Private
            Limited], it will take [180 days approx] for the refund to be
            processed to you. uch an event, you may choose to reject the product
            at the doorstep
          </li>
        </ol>
        <h4 className="text-2xl font-semibold mt-6 mb-3 text-start">
          Returns Policy
        </h4>
        <p className="mb-3 text-start font-semibold">
          We offer refund / exchange within first [2] days from the date of your
          purchase. If [2] days have passed since your purchase, you will not be
          offered return, exchange or refund of any kind. In order to become
          eligible for a return or an exchange
        </p>
        <ol className="" type="I">
          <li className="text-start mb-2">
            the purchased item should be unused and in the same condition as you
            received it.
          </li>
          <li className="text-start mb-2">
            the item must have original packaging,
          </li>
          <li className="text-start mb-2">
            if the item that you purchased on a sale or any offer , then the
            item may not be eligible for a return/exchange. Further, only such
            items are replaced by us (based on an exchange request), if such
            items are found defective or damaged.
          </li>
          <li className="text-start mb-2">
            If you need to place a return / exchange request for an eligible
            product/ item (as applicable) you need to send us an email
            [Info@ceyoneev.com].
          </li>
        </ol>
        <p className="mb-3 text-start font-semibold">
          You agree that there may be a certain category of products / items
          that are exempted from returns or refunds. Such categories of the
          products would be identified to you at the item of purchase. For
          exchange / return accepted request(s) (as applicable), once your
          returned product / item is received and inspected by us, we will send
          you an email to notify you about receipt of the returned / exchanged
          product. Further. If the same has been approved after the quality
          check at our end, your request (i.e. return / exchange) will be
          processed in accordance with our policies.
        </p>
        <h4 className="text-2xl font-semibold mt-6 mb-3 text-start">
          Shipping Policy
        </h4>
        <p className="mb-3 text-start font-semibold">
          The orders for the user are shipped through registered domestic
          courier companies and/or speed post only. Order are shipped within [2]
          to [15] days from the date of the order and/or payment or as per the
          delivery date agreed at the time of order confirmation and delivering
          of the shipment, subject to courier company / post office norms.
          Platform Owner shall not be liable for any delay in delivery by the
          courier company / postal authority. Delivery of all orders will be
          made to the address provided by the buyer at the time of purchase.
          Delivery of our services will be confirmed on your email ID as
          specified at the time of registration. If there are any shipping
          cost(s) levied by the seller or the Platform Owner (as the case be),
          the same is not refundable. For any issues in utilizing our services
          you may contact our helpdesk on [info@ceyoneev.com].
        </p>
      </div>
      <Footer />{" "}
    </>
  );
};

export default PrivacyPolicy;
